import Heading from 'src/components/Heading/Heading';
import { Button } from '@get-mosh/msh-shared';
import Image from 'next/image';
import PhoneFrame from 'src/components/PhoneFrame/PhoneFrame';
import { trackClick } from 'src/analytics/Events';
import EventNames from 'src/analytics/EventNames';
import Routes from 'src/constants/Routes';

const HomeMessageDoctor = () => {
    const handleClick = () => {
        trackClick(EventNames.BUTTON_MESSAGE_YOUR_DOCTOR_CLICKED, {
            destination_url: Routes.HOW_IT_WORKS,
        });
    };

    return (
        <div className="container sm:pt-8 xl:pt-0">
            <div className="flex flex-row flex-wrap items-center space-y-4 lg:flex-nowrap lg:space-x-4 lg:space-y-0">
                <div className="w-full pb-4 text-center lg:w-1/2 lg:pb-0">
                    <Heading level={'h2'} tag={'h'} centered={true}>
                        Message your practitioner any time
                    </Heading>
                    <Image
                        src={
                            '/images/home/messageyourdoctor/message-your-doctor.png'
                        }
                        width={513}
                        height={113}
                        alt={'Message your practitioner any time'}
                        className={
                            'mx-auto block max-w-[280px] pb-4 pt-4 md:max-w-[325px]'
                        }
                    />
                    <Button
                        as="a"
                        href={Routes.HOW_IT_WORKS}
                        text="Find out more"
                        onClick={handleClick}
                        stretch
                    />
                </div>
                <div className="w-full lg:w-1/2">
                    <PhoneFrame />
                </div>
            </div>
        </div>
    );
};

export default HomeMessageDoctor;
