import Heading from 'src/components/Heading/Heading';
import classNames from 'classnames';
import CtaTile, { CtaTileProps } from 'src/components/Cta/CtaTile';
import common from '../../data/common.json';
import styles from './home.module.scss';
import { trackClick } from 'src/analytics/Events';
import EventNames from 'src/analytics/EventNames';
import Routes from 'src/constants/Routes';
import { Button } from '@get-mosh/msh-shared';

type HomeHeroProps = {
    cta: {
        items: CtaTileProps[];
    };
};

const HomeHero = ({ cta }: HomeHeroProps) => {
    const handleFindTreatmentClick = () => {
        trackClick(EventNames.BUTTON_FIND_MY_TREATMENT_CLICKED, {
            location: 'mobile',
            destination_url: Routes.QUIZZES,
        });
        window.location.href = Routes.QUIZZES;
    };

    return (
        <>
            <div className="px-4 md:container">
                <Heading level={'h1'} tag={'h'} className={'mb-2'}>
                    Men's health stuff made easy.
                </Heading>
                <p className="mb-8 text-[2.25ch]">
                    Personalised clinical treatments. All online.
                </p>
            </div>
            <div className="md:container xl:px-0">
                <div
                    className={classNames(
                        styles['hero-tiles'],
                        styles['hero-tiles--carousel'],
                    )}
                    data-hero-cta-tiles
                >
                    {cta.items.map((tile, i) => (
                        <div
                            className="w-[230px] shrink-0 snap-x snap-start scroll-ml-4 scroll-mr-4 rounded shadow-md md:w-full xl:w-[230px] xxl:ml-16"
                            key={i}
                        >
                            <CtaTile {...tile} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="block px-4 sm:container sm:px-0 md:hidden">
                <div className="mx-auto w-full text-center">
                    <p className="mb-4">
                        Not sure which treatment is right for you?
                    </p>
                    <Button
                        as="a"
                        href="/quizzes"
                        onClick={handleFindTreatmentClick}
                        text={common.findTreatment}
                        className={'mx-0 w-full'}
                    />
                </div>
            </div>
        </>
    );
};

export default HomeHero;
