'use client';

import classNames from 'classnames';
import styles from './home.module.scss';
import { useTrackPage, trackClick } from 'src/analytics/Events';
// Data
import testimonialsData from 'src/data/testimonials.json';
import homeData from 'src/data/home.json';
import howItWorksData from 'src/data/howItWorks.json';
import commonData from 'src/data/common.json';
// Layout
import LayoutHome from 'src/components/Layout/Home';
// Template Parts
import HomeHero from 'src/views/Home/HomeHero';
import HomeOurDoctors from 'src/views/Home/HomeOurDoctors';
import HomeMessageDoctor from './HomeMessageDoctor';
import HomeSafeHands from './HomeSafeHands';
import HomeSupportingOurBoys from './HomeSupportingOurBoys';
import HomeServicesInfo from './HomeServicesInfo';
// Components
import Heading from 'src/components/Heading/Heading';
import HomeInfoGrid from './HomeInfoGrid';
import HowItWorks from 'src/components/HowItWorks/HowItWorks';
import { Button } from '@get-mosh/msh-shared';
import HomeSeoContent from './HomeSeoContent';
import Routes from 'src/constants/Routes';
import EventNames from 'src/analytics/EventNames';
import SeoContent from 'src/components/SeoContent/SeoContent';
import { faArrowRightLong } from '@fortawesome/pro-solid-svg-icons';
import Testimonials from 'src/components/Testimonials/Testimonials';

const heroCta = {
    items: [
        {
            label: 'Hair<br/>loss',
            iconAfterLabel: faArrowRightLong,
            iconColor: 'green',
            url: '/hair-loss',
            className: '',
            image: {
                src: '/images/home/treatments/tile-hair-loss.jpg',
                width: 1300,
                height: 975,
                alt: 'Hair loss',
            },
        },
        {
            label: 'Weight<br/>loss',
            iconAfterLabel: faArrowRightLong,
            iconColor: 'green',
            url: '/weight-loss',
            className: '',
            image: {
                src: '/images/home/treatments/new-weight-loss.jpg',
                width: 424,
                height: 347,
                alt: 'label',
            },
        },
        {
            label: 'Erectile<br/>dysfunction',
            iconAfterLabel: faArrowRightLong,
            iconColor: 'green',
            url: '/erectile-dysfunction',
            className: '',
            image: {
                src: '/images/home/treatments/erectile.jpg',
                width: 424,
                height: 576,
                alt: 'label',
            },
        },
        {
            label: 'Mental<br/>health',
            iconAfterLabel: faArrowRightLong,
            iconColor: 'green',
            url: '/mental-health',
            className: '',
            image: {
                src: '/images/home/treatments/mental-health.jpg',
                width: 425,
                height: 409,
                alt: 'label',
            },
        },
        {
            label: 'Enlarged<br/>prostate',
            iconAfterLabel: faArrowRightLong,
            iconColor: 'green',
            url: '/start/prostate-enlargement-health-check',
            className: '',
            image: {
                src: '/images/home/treatments/prostate.jpg',
                width: 425,
                height: 409,
                alt: 'label',
            },
        },
        {
            label: 'Premature<br/>ejaculation',
            iconAfterLabel: faArrowRightLong,
            iconColor: 'green',
            url: '/premature-ejaculation',
            className: '',
            image: {
                src: '/images/home/treatments/premature.jpg',
                width: 425,
                height: 486,
                alt: 'label',
            },
        },
        {
            label: 'Skin<br/>care',
            iconAfterLabel: faArrowRightLong,
            iconColor: 'green',
            url: '/skin',
            className: '',
            image: {
                src: '/images/home/treatments/skincare.jpg',
                width: 318,
                height: 329,
                alt: 'label',
            },
        },
        {
            label: 'Health<br/>store',
            iconAfterLabel: faArrowRightLong,
            iconColor: 'green',
            url: '/non-prescription',
            className: '',
            image: {
                src: '/images/home/treatments/health-store.jpg',
                width: 318,
                height: 306,
                alt: '',
            },
        },
        {
            label: 'Find my<br/>treatment',
            iconAfterLabel: faArrowRightLong,
            iconColor: 'green',
            url: '/quizzes',
            className: '',
            image: {
                src: '/images/home/treatments/find-my-treatment.jpg',
                width: 317,
                height: 264,
                alt: 'label',
            },
        },
    ],
};

const Home = () => {
    const handleFindTreatmentClick = () => {
        trackClick(EventNames.BUTTON_FIND_MY_TREATMENT_CLICKED, {
            location: 'footer',
            destination_url: Routes.QUIZZES,
        });
    };

    useTrackPage('Home');

    return (
        <LayoutHome className={styles['home']}>
            <section
                className={classNames(
                    'bg-sand-light py-8 xl:py-12',
                    styles['hero'],
                )}
            >
                <HomeHero cta={heroCta} />
            </section>
            <section className={classNames('bg-green-light py-4 xl:py-8')}>
                <HomeInfoGrid data={homeData.infoGrid} />
            </section>
            <section className={classNames('bg-sand-light py-8 xl:py-12')}>
                <HowItWorks data={howItWorksData.default} />
            </section>
            <section className={classNames('bg-white py-8 xl:py-12')}>
                <HomeOurDoctors data={homeData.ourDoctors} />
            </section>
            <section
                className={classNames('bg-green-dark pt-8 text-white lg:pt-0')}
            >
                <HomeMessageDoctor />
            </section>
            <section className={classNames('bg-sand-light py-8 xl:py-12')}>
                <HomeSafeHands data={commonData.safeHands} />
            </section>
            <section className={classNames('bg-green-light py-8 md:py-16')}>
                <Testimonials list={testimonialsData.generic} />
            </section>
            <section className={classNames('bg-sand-light py-12 xl:py-16')}>
                <HomeSupportingOurBoys data={commonData.supportingOurBoys} />
            </section>
            <section className={classNames('bg-white py-8 xl:py-12')}>
                <HomeServicesInfo data={commonData.servicesInfo} />
            </section>
            <section className={classNames('bg-green-light py-8 xl:py-12')}>
                <Heading
                    level={'h2'}
                    tag={'h'}
                    centered={true}
                    className="mb-4"
                >
                    Ready to get started? Let's do it!
                </Heading>
                <div className="mx-auto block px-4 text-center">
                    <Button
                        as="a"
                        href={Routes.QUIZZES}
                        onClick={handleFindTreatmentClick}
                        text={commonData.findTreatment}
                        className="mb-0"
                        stretch
                    />
                </div>
            </section>
            <SeoContent title={'More about Mosh'}>
                <HomeSeoContent />
            </SeoContent>
        </LayoutHome>
    );
};

export default Home;
