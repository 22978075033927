import styles from './cta.module.scss';
import classNames from 'classnames';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HTMLReactParser from 'html-react-parser';
import { trackClick } from 'src/analytics/Events';
import EventNames from 'src/analytics/EventNames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Link from 'next/link';

export type CtaTileProps = {
    label: string;
    url: string;
    image: {
        src: string;
        width: number;
        height: number;
        alt: string;
    };
    iconAfterLabel?: IconProp;
    className?: string;
};

const CtaTile = (props: CtaTileProps) => {
    const handleOnClick = () => {
        trackClick(EventNames.CATEGORY_TILE_CLICKED, {
            label: props.label,
            destination_url: props.url,
        });
    };

    return (
        <Link href={props.url} title={props.label} onClick={handleOnClick}>
            <article
                className={classNames(
                    'cta-tile',
                    styles['tile'],
                    'flex w-full flex-wrap',
                    'cursor-pointer shadow-sm',
                    props.className,
                )}
                data-cta-tile
            >
                <header>
                    <Image
                        priority
                        src={props.image.src}
                        width={props.image.width}
                        height={props.image.height}
                        alt={props.image.alt}
                    />
                </header>

                <section className="bg-green-dark px-4 py-2 uppercase text-white md:p-4">
                    <div className="flex w-full flex-nowrap items-center justify-between">
                        <span className="mr-4 py-6 lg:text-sm">
                            {HTMLReactParser(props.label)}
                        </span>
                        {props.iconAfterLabel && (
                            <span
                                className={classNames(
                                    'tile--icon-after-label hidden md:block',
                                )}
                            >
                                <FontAwesomeIcon icon={props.iconAfterLabel} />
                            </span>
                        )}
                    </div>
                </section>
            </article>
        </Link>
    );
};

export default CtaTile;
