import Image from 'next/image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './home.module.scss';
import classNames from 'classnames';
import { trackClick } from 'src/analytics/Events';
import EventNames from 'src/analytics/EventNames';
import { faArrowRightLong } from '@fortawesome/pro-solid-svg-icons';

const HomeServicesInfo = ({ data }) => {
    return (
        <div className="container">
            <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 md:gap-x-8 lg:grid-cols-3">
                {data.items.map((e, i) => {
                    return <ServiceBlock service={e} key={i} />;
                })}
            </div>
        </div>
    );
};

const ServiceBlock = ({ service }) => {

    const handleLinkClick = (item) => {
        trackClick(EventNames.CATEGORY_SUBPAGE_LINK_CLICKED, {label : item.label, destination_url: item.url})
        window.location.href = item.url;
    }

    return (
        <article className="">
            <header className="flex justify-between items-center mb-4">
                <p className="font-heading text-lg tracking-tight">
                    {service.title}
                </p>
                <Image
                    src={service.image.src}
                    width={service.image.width}
                    height={service.image.height}
                    alt={service.title}
                    className={'w-[60px]'}
                />
            </header>
            <section>
                <p className="mb-4">{service.description}</p>
                <ul>
                    {service.services.map((e, i) => {
                        return (
                            <li key={i}>
                                <a
                                    className={classNames(
                                        styles['service--link'],
                                        'font-bolder py-2 flex justify-between items-center pr-4 hover:pr-3 transition-all'
                                    )}
                                    href={e.url}
                                    onClick={()=> {handleLinkClick(e)}}
                                    title={e.label}
                                >
                                    {e.label}
                                    <FontAwesomeIcon
                                        icon={faArrowRightLong}
                                        className="rounded-full bg-[#F5F5F5] p-2 w-8 h-8"
                                    />
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </section>
        </article>
    );
};

export default HomeServicesInfo;
