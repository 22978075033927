import classNames from 'classnames';
import { useState, useEffect } from 'react';
import Image from 'next/image'
import maps from 'src/data/maps.json';
import styles from './home-info-grid.module.scss';
const breakpoint = (screen) => {
    return parseInt(screen.split('px')[0]);
};

const HomeInfoGrid = ({ data }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [items, setItems] = useState(data.items);

    const checkIfMobile = () => {
        if (window.innerWidth < breakpoint(maps.breakpoints.xl)) {
            setIsMobile(true);
            setItems([...data.items, ...data.items, ...data.items]);
        } else if (window.innerWidth >= breakpoint(maps.breakpoints.xl)) {
            setIsMobile(false);
            setItems(data.items);
        }
    };

    useEffect(() => {
        checkIfMobile();
        window.addEventListener('resize', () => {
            checkIfMobile();
        });
    },[]);
    const count = { '--info-grid-count': items.length } as React.CSSProperties;
    return (
        <div
            className={classNames(
                'xl:container overflow-hidden',
                styles['info-grid']
            )}
            style={count}
        >
            <div
                className={classNames(
                    styles['info-grid__track'],
                    isMobile && styles['info-grid__carousel']
                )}
            >
                {items.map((e, i) => {
                    return (
                        <div
                            className={classNames(
                                'text-center',
                                styles['info-grid__item']
                            )}
                            key={i}
                        >
                            <Image
                                src={e.image.src}
                                width={e.image.width}
                                height={e.image.height}
                                alt={e.label}
                                className={'mx-auto mb-4 w-auto'}
                            />
                            <span>{e.label}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default HomeInfoGrid;
