import Heading from 'src/components/Heading/Heading';
import Routes from 'src/constants/Routes';

const HomeSeoContent = () => {
    const tabIndexValue = -1;
    return (
        <>
            <Heading level={'h2'} tag={'h'}>
                Men's Health Clinic
            </Heading>
            <p>
                Mosh is a men's health clinic with Australian doctors and nurse practitioners who are
                passionate about their work. We have all the functionality of a{' '}
                <a
                    tabIndex={tabIndexValue}
                    href="/hair-loss/clinic"
                    target="_blank"
                    rel="noopener"
                >
                    hair loss clinic
                </a>
                , an{' '}
                <a
                    tabIndex={tabIndexValue}
                    href={Routes.SKIN_ANTI_AGEING}
                    target="_blank"
                    rel="noopener"
                >
                    anti-ageing clinic
                </a>
                , and a sexual health centre, but with the added benefit of
                patient convenience. Our 100% online men's health clinic
                encompasses a wide range of well-being categories - hair, skin,
                mental health, and sexual health, to name a few.&nbsp;
            </p>

            <Heading level={'h3'} tag={'h'}>
                Professional help
            </Heading>

            <p>
                We have partnered with AHPRA-registered independent doctors
                and nurse practitioners who are based in Australia.
                Additionally, the pay we give our practitioners does not depend on how
                many prescriptions they write as they are paid on a
                fee-for-service basis. This type of set-up makes it so that you
                can consult with an unbiased professional who genuinely wants
                what is best for you.&nbsp;
            </p>
            <p>
                At Mosh, we are committed to giving men the treatment they
                deserve. Whether you are asking, '
                <a
                    tabIndex={tabIndexValue}
                    href="/blog"
                    target="_blank"
                    rel="noopener"
                >
                    Why is my hair falling out
                </a>
                ?' or simply looking for{' '}
                <a
                    tabIndex={tabIndexValue}
                    href={Routes.MENTAL_HEALTH_LANDING_PAGE}
                    target="_blank"
                    rel="noopener"
                >
                    mental health support
                </a>
                , our health practitioners and services will surely benefit your specific
                lifestyle. This is because Mosh operates by systematically
                creating a customised treatment plan for your unique
                needs.&nbsp;
            </p>

            <Heading level={'h3'} tag={'h'}>
                Subscription service
            </Heading>

            <p>
                Our men's health clinic subscription service makes it so that
                you can save not only money but time as well. Instead of
                physically going to a men's health clinic, our service allows
                you to set and forget. This way, you do not have to make
                reminders or take mental notes since we automatically provide
                refills as needed.&nbsp;
            </p>
            <p>
                Our process will certainly allow you to have a convenient,
                effortless experience. No matter the treatment your health practitioner has
                suggested for your men's health issues - whether that be{' '}
                <a
                    tabIndex={tabIndexValue}
                    href={Routes.HAIR_LOSS_LANDING_PAGE}
                    target="_blank"
                    rel="noopener"
                >
                    hair loss treatment
                </a>{' '}
                or{' '}
                <a
                    tabIndex={tabIndexValue}
                    href={Routes.ED_LANDING_PAGE}
                    target="_blank"
                    rel="noopener"
                >
                    erectile dysfunction treatment
                </a>{' '}
                - our subscription service will be sure to deliver your orders
                swiftly and discreetly.&nbsp;
            </p>

            <Heading level={'h3'} tag={'h'}>
                How can I consult a health practitioner?
            </Heading>

            <p>
                Through the online men's health clinic at Mosh, consulting with
                a doctor or nurse practitioner for a specific treatment process has been made easy and
                accessible.&nbsp;
            </p>
            <p>
                Once you sign up as a patient under our men's health clinic, we
                will provide you with simple questions about your health,
                medicine, and therapy history. Don't worry, we won't bombard you
                with questions. This step will just take about a few minutes to
                complete.&nbsp;
            </p>
            <p>
                With this initial information from the questions, our doctors
                and nurse practitioners will be able to assess your condition and symptoms. If treatment
                is suitable for your situation, an Australian health practitioner will review
                and consult with you through call, video conference, or even
                text.&nbsp;
            </p>
            <p>
                The consultation sessions at our online men's health clinic can
                be scheduled based on your availability or internal progress.
                Our consultations will serve as a professional space for men's
                health advice and even a way to decide if current treatments
                should be phased out for newer options.&nbsp;
            </p>
            <p>
                After listening to what our men's health practitioner has to say, your
                personal treatment will be delivered right to your doorstep.
                With our online men's health platform, you will never have to
                worry about awkward pharmacy interactions and complicated
                surgeon visits for drastic prosthetic operations.
            </p>

            <Heading level={'h3'} tag={'h'}>
                What information can I get about the treatment?
            </Heading>

            <p>
                One of our main principles at Mosh is honesty and transparency.
                Through our men's health clinic, you can ask an Australian
                health practitioner all sorts of questions about your personal treatment. Our
                consultations, through the online men's health platform, allow
                both the patient and the practitioner to be completely honest with
                each other.&nbsp;
            </p>
            <p>
                This means that you can present and communicate concerns about a
                private medical problem that you have been too shy to deal with.
                Whether it be about urological issues or sexual health concerns,
                our doctors and nurse practitioners are here to give you the proper care and
                attention.&nbsp;
            </p>
            <p>
                Additionally, when you are not in consultation with your health practitioner,
                you can look at informative men's health online articles on the
                'Knowledge Hub' portion of the Mosh website. We have medical
                categories that range from sexual health to skincare - so read
                up and find the answers to '
                <a
                    tabIndex={tabIndexValue}
                    href="/premature-ejaculation"
                    target="_blank"
                    rel="noopener"
                >
                    how to stop premature ejaculation
                </a>
                ' or 'how to transition your skincare routine from winter to
                spring'.
            </p>
            <p>
                Bring any topic up to your next consultation, provided that it
                applies to your unique situation. Allow our health practitioners to analyse
                and assess your situation so that you can get all the
                information you need about your current or future
                treatment.&nbsp;
            </p>

            <Heading level={'h3'} tag={'h'}>
                How long do orders take to be delivered?
            </Heading>

            <p>
                Our delivery speed complements the quality of our men's health
                clinic subscription service. We provide fast and free delivery
                for orders all around Australia.
            </p>
            <p>
                For the majority of orders from our clinic for men's health,
                preparation and delivery will take about four to seven business
                days. In this time frame, the Mosh team will take note of the
                order data and coordinate with the respective pharmacy.
                Afterwards, the products for your personalised medical treatment
                will be arranged and packed through Australia Post.&nbsp;
            </p>
            <p>
                This means that after you have paid through our trusted payment
                service providers such as Afterpay, Amex, and MasterCard, all
                that is left to do is wait for your discreetly packaged delivery
                to arrive in front of your door.&nbsp;
            </p>
            <p>
                Now that you know the specific process at the Mosh men's health
                clinic, sign up now and have professional consultations with
                independent doctors and nurse practitioners. Let our service change your
                life for the better!
            </p>
        </>
    );
};

export default HomeSeoContent;
