import Heading from 'src/components/Heading/Heading';
import HTMLReactParser from 'html-react-parser';
import Image from 'next/image'
const HomeSafeHands = ({ data }) => {
    return (
        <div className="container">
            <Heading centered={true} level={'h2'} className={'mb-4'}>
                You're in safe hands
            </Heading>
            <p className="text-center mb-2 md:max-w-xl md:mx-auto">
                Tens of thousands of people have trusted Mosh with their
                healthcare needs and we take pride in providing the best
                service.
            </p>
            <div className="flex flex-nowrap overflow-x-auto space-x-4 py-8 snap-x snap-mandatory md:overflow-visible md:grid md:grid-cols-2 md:gap-x-4 md:gap-y-4 md:space-x-0 lg:grid-cols-4">
                {data.length &&
                    data.map((e, i) => {
                        return <div className='w-2/3 snap-start shrink-0 bg-white text-center p-4 rounded-xl shadow-md md:w-full' key={i}>
                            <Image src={e.image.src} width={e.image.width} height={e.image.height} alt={e.label} className={'block mx-auto text-center w-[60px]'} />
                            <p className='mb-2 font-bolder'>{e.label}</p>
                            <p className='mb-4'>{HTMLReactParser(e.description)}</p>
                        </div>;
                    })}
            </div>
        </div>
    );
};

export default HomeSafeHands;
