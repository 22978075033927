import Heading from 'src/components/Heading/Heading';
import Card from 'src/components/Card/Card';
import Accordion from 'src/components/Accordion/Accordion';
import doctorsData from 'src/data/doctors.json';
import { Card as CardType } from 'src/types/Card';
import classNames from 'classnames';

type HomeOurDoctorsProps = {
    data: {
        cards: Array<CardType>;
    };
};

const HomeOurDoctors = ({ data }: HomeOurDoctorsProps) => {
    return (
        <div className="container md:pb-16">
            <div className="mb-4 w-full text-center md:hidden">
                <Heading level={'h2'} tag={'h'} className={'mb-4'}>
                    Aussie doctors are here to help
                </Heading>
                <p className="mb-8 block">
                    We work with leading Australian doctors who work with you to
                    understand your health needs.
                </p>
            </div>
            <div className="flex flex-wrap content-center items-center lg:flex-nowrap lg:space-x-8">
                <div className="mb-8 w-full overflow-x-auto lg:mb-0 lg:w-1/2">
                    <div className="flex snap-x snap-mandatory scroll-pr-8 flex-nowrap space-x-4 pb-4">
                        {data.cards.map((item, index) => (
                            <div
                                key={index}
                                className={classNames(
                                    'w-[80%] shrink-0 px-[2px] sm:w-[40%] lg:w-1/2',
                                )}
                            >
                                <Card
                                    name={item.name}
                                    image={item.image}
                                    subtitle={item.subtitle}
                                    type={'generic'}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex w-full items-center md:h-fit lg:w-1/2">
                    <Accordion
                        items={doctorsData.doctorsFaq}
                        isTitleBolded
                        openItemOnStart={0}
                    />
                </div>
            </div>
        </div>
    );
};

export default HomeOurDoctors;
